@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap");

.allAbout{
  overflow-x: hidden;
}
.main_about {
  height: auto;
  width: 100vw;
  display: flex;
  flex-direction: column;
  background-color: #00629b;
  overflow-x: hidden;
}
/* .hero_about {
  flex: 0.7;
  background-image: url(./images/about-img.jpg);
  background-position: top;
  background-size: cover;
  width: 100%;
  background-color: #00629b;
  height: 100px;
} */
.content_about {
  flex: 1.5;
  display: flex;
  flex-direction: column;
  background-color: white;
  height: auto;
}

.h_about {
  text-align: center;
  font-weight: 600;
  flex: 0.1;
  font-size: 24pt;
  color: #00629b;
  margin-top: 2rem;
  margin-bottom: 2rem;

}
.main_content {
  gap: 20px;
  display: flex;
  flex-direction: row;
  flex: 4;
  background-color: rgb(255, 255, 255);
  margin: 5%;
  margin-top: 0;
}
.main_c1 {
 
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: #f2f2f2;
  border-radius: 30px;
  
  align-items: center;
   
}
.main_c1 p {
  margin: 40px;
/* 
  font-size: 24px; */
  fontfamily: "Inter, sans-serif";
  font-weight: 400;

  text-align: center;
 
}
.main_c2 {
  gap: 20px;
  display: flex;
  flex-direction: column;
  flex: 1;
}
.sub_1 {
  align-items: last baseline;
  justify-content: center;
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #f2f2f2;
  border-radius: 30px;
}
.sub_1 p {
  margin: 30px;
  margin-inline: 50px;
  margin-bottom: 5%;
  fontfamily: "Inter, sans-serif";
  font-weight: 400;
  color: #2a2a2a;
  text-align: center;
}

.image_1 {
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;

}

.main_c1 hr {
  width: 50%;
  height: 5px;
  background-color: white;
  margin-top: 2%;
}
.about_icons{
  width: 100%;
  height: 5%;
}

.images {
  width: 20%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5%;
  background-size: contain;
  background-repeat: no-repeat;
}

@media (max-width: 768px) { /* You can adjust the max-width value as needed */
  .main_content {
    flex-direction: column; /* Change to column layout on mobile */
  }
}

