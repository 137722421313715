
.finance-team1 {
  letter-spacing: 0.02em;
  text-transform: capitalize;
  font-family: inherit;
}

.finance-team1 {
  margin: 0;
  flex: 1;
  position: relative;
  font-size: 9.38rem;
  line-height: 1.06rem;
  font-weight: 900;
  color: rgba(255, 255, 255, 0.05);
  display: inline-block;
  max-width: 100%;
  z-index: 1;
}
.membership-development-team-fr-inner {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  max-width: 100%;
}

.membership-development-team-fr-inner {
  align-self: stretch;
  justify-content: flex-end;
}
.lorem-ipsum-dolor-container4 {
  position: relative;
  font-size: 0.94rem;
  letter-spacing: 0.02em;
  line-height: 2.19rem;
  text-transform: capitalize;
  text-align: justify;
  display: inline-block;
}
.frame-child9 {
  height: 18.5rem;
  width: 18.25rem;
  position: relative;
  border-radius: 200px 200px 0 0;
  background: linear-gradient(180deg, #ffa300, rgba(255, 163, 0, 0));
  display: none;
}
.lead-pic-icon3 {
  height: 15.96rem;
  width: 15.96rem;
  position: relative;
  z-index: 1;
}
.rectangle-parent8 {
  border-radius: 200px 200px 0 0;
  background: linear-gradient(180deg, #ffa300, rgba(255, 163, 0, 0));
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 1.25rem 1.19rem 1.31rem 1.13rem;
}
.pasan-senarath1 {
  margin-block-start: 0;
  margin-block-end: 20px;
}
.leader3 {
  margin: 0;
  color: #ffa300;
}
.pasan-senarath-leader-container {
  position: relative;
  line-height: 0.75rem;
  text-transform: capitalize;
  display: inline-block;
}
.leader-frame {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 1.31rem;
  width: 25rem;
}
.frame-child10 {
  height: 18.5rem;
  width: 18.25rem;
  position: relative;
  border-radius: 200px 200px 0 0;
  background: linear-gradient(180deg, #00b5e2, rgba(0, 181, 226, 0));
  transform: rotate(-180deg);
  display: none;
}
.member-icon7 {
  height: 16.34rem;
  width: 16.34rem;
  position: relative;
  z-index: 1;
}
.rectangle-parent9 {
  border-radius: 200px 200px 0 0;
  background: linear-gradient(180deg, #00b5e2, rgba(0, 181, 226, 0));
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0.88rem 0.88rem 1.31rem;
}
.shanaya-pereira {
  margin-block-start: 0;
  margin-block-end: 20px;
}
.member7 {
  margin: 0;
  color: #00b5e2;
}
.shanaya-pereira-member-container {
  height: 2.75rem;
  position: relative;
  line-height: 0.75rem;
  text-transform: capitalize;
  display: inline-block;
}
.leader-frame1 {
  width: 25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 1.31rem;
}
.frame-child11 {
  height: 18.5rem;
  width: 18.25rem;
  position: relative;
  border-radius: 200px 200px 0 0;
  background: linear-gradient(180deg, #00b5e2, rgba(0, 181, 226, 0));
  display: none;
}
.member-icon8 {
  height: 16.34rem;
  width: 16.34rem;
  position: relative;
  z-index: 1;
}
.rectangle-parent10 {
  border-radius: 200px 200px 0 0;
  background: linear-gradient(180deg, #00b5e2, rgba(0, 181, 226, 0));
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 1.06rem 0.94rem 1.13rem;
}
.kavindri-perera1 {
  margin-block-start: 0;
  margin-block-end: 20px;
}
.member8 {
  margin: 0;
  color: #00b5e2;
}
.kavindri-perera-member-container1 {
  height: 2.75rem;
  position: relative;
  line-height: 0.75rem;
  text-transform: capitalize;
  display: inline-block;
}
.leader-frame-parent,
.leader-frame2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.leader-frame2{
  width: 25rem;
  gap: 1.31rem;
}
.leader-frame-parent {
  flex-direction: row;
  align-items: flex-start;
  max-width: 100%;
  text-align: center;
  font-size: 1.75rem;
}
.membership-development-team-fr,
.publicity-frame1 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 100%;
}
.publicity-frame1 {
  width: 82.44rem;
  align-items: center;
  gap: 3.88rem;
  text-align: justify;
  font-size: 0.94rem;
  color: #fff;
}
.membership-development-team-fr {
  align-self: stretch;
  width: 82.44rem;
    margin: 0 auto;
  align-items: flex-start;
  box-sizing: border-box;
  gap: 3.5rem;
  text-align: center;
  font-size: 2.63rem;
  color: #ffa300;
  font-family: Montserrat;
}
@media screen and (max-width: 1200px) {
  .publicity-frame1 {
    gap: 3.88rem;
  }
}
@media screen and (max-width: 1050px) {
  .finance-team {
    font-size: 2.13rem;
    line-height: 1.75rem;
  }
  .finance-team1 {
    font-size: 3.75rem;
    line-height: 1.31rem;
  }
  .leader-frame-parent {
    gap: 3rem;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
  }
}
@media screen and (max-width: 750px) {
  .finance-team {
    font-size: 1.56rem;
    line-height: 1.31rem;
  }
  .finance-team1 {
    font-size: 2.31rem;
    line-height: 0.88rem;
  }
  .kavindri-perera-member-container1,
  .pasan-senarath-leader-container,
  .shanaya-pereira-member-container {
    font-size: 1.38rem;
    line-height: 0.63rem;
  }
  .membership-development-team-fr {
    gap: 2.56rem;
  }
  .leader-frame-parent {
    justify-content: center;
    gap: 1.94rem;
  }
  .membership-development-team, .finance-container{
    text-align: center;
    justify-content: center;
    margin: 0 auto;
  }
  .leader-frame-parent{
    justify-content: center;
  }
}
@media screen and (max-width: 450px) {
  .finance-team {
    font-size: 1.56rem;
    line-height: 1.31rem;
  }
  .finance-team1 {
    font-size: 2.31rem;
    line-height: 0.88rem;
  }
  .kavindri-perera-member-container1,
  .pasan-senarath-leader-container,
  .shanaya-pereira-member-container {
    font-size: 1.38rem;
    line-height: 0.63rem;
  }
  .membership-development-team-fr {
    gap: 2.56rem;
  }
  .leader-frame-parent {
    justify-content: center;
    gap: 1.94rem;
  }
  .membership-development-team, .finance-container{
    text-align: center;
    justify-content: center;
    margin: 0 auto;
  }
  .leader-frame-parent{
    justify-content: center;
  }
}
