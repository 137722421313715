.lorem-ipsum-dolor-container3,
.membership-development-team {
  position: relative;
  letter-spacing: 0.02em;
  line-height: 2.19rem;
  text-transform: capitalize;
  display: inline-block;
}
.membership-development-team {
  margin: 0;
  font-size: inherit;
  font-weight: 700;
  font-family: inherit;
  max-width: 100%;
}
.lorem-ipsum-dolor-container3 {
  position: relative;
  font-size: 0.94rem;
  letter-spacing: 0.02em;
  line-height: 2.19rem;
  text-transform: capitalize;
  text-align: justify;
  display: inline-block;
  text-transform: capitalize;
  color: #fff;
  text-align: justify;
}
.frame-child6 {
  height: 18.5rem;
  width: 18.25rem;
  position: relative;
  border-radius: 200px 200px 0 0;
  background: linear-gradient(180deg, #ffa300, rgba(255, 163, 0, 0));
  display: none;
}
.lead-pic-icon2 {
  height: 15.96rem;
  width: 15.96rem;
  position: relative;
  z-index: 1;
}
.rectangle-parent5 {
  border-radius: 200px 200px 0 0;
  background: linear-gradient(180deg, #ffa300, rgba(255, 163, 0, 0));
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 1.25rem 1.19rem 1.31rem 1.13rem;
}
.mahdy-abdullah-nawfar {
  margin-block-start: 0;
  margin-block-end: 20px;
}
.leader2 {
  margin: 0;
  color: #ffa300;
}
.mahdy-abdullah-nawfar-container {
  align-self: stretch;
  position: relative;
  line-height: 0.75rem;
  text-transform: capitalize;
}
.lead-pic-anjula {
  width: 22.19rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 1.31rem;
  max-width: 100%;
}
.frame-child7 {
  height: 18.5rem;
  width: 18.25rem;
  position: relative;
  border-radius: 200px 200px 0 0;
  background: linear-gradient(180deg, #00b5e2, rgba(0, 181, 226, 0));
  transform: rotate(-180deg);
  display: none;
}
.member-icon5 {
  height: 16.34rem;
  width: 16.34rem;
  position: relative;
  z-index: 1;
}
.rectangle-parent6 {
  border-radius: 200px 200px 0 0;
  background: linear-gradient(180deg, #00b5e2, rgba(0, 181, 226, 0));
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0.88rem 0.88rem 1.31rem;
}
.thiruni-bandara {
  margin-block-start: 0;
  margin-block-end: 20px;
}
.member5 {
  margin: 0;
  color: #00b5e2;
}
.thiruni-bandara-member-container {
  width: 14.75rem;
  height: 2.75rem;
  position: relative;
  line-height: 0.75rem;
  text-transform: capitalize;
  display: inline-block;
}
.lead-pic-anjula1 {
  width: 21rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 1.31rem;
  max-width: 100%;
}
.frame-child8 {
  height: 18.5rem;
  width: 18.25rem;
  position: relative;
  border-radius: 200px 200px 0 0;
  background: linear-gradient(180deg, #00b5e2, rgba(0, 181, 226, 0));
  display: none;
}
.member-icon6 {
  height: 16.34rem;
  width: 16.34rem;
  position: relative;
  z-index: 1;
}
.rectangle-parent7 {
  border-radius: 200px 200px 0 0;
  background: linear-gradient(180deg, #00b5e2, rgba(0, 181, 226, 0));
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 1.06rem 0.94rem 1.13rem;
}
.kavindri-perera {
  margin-block-start: 0;
  margin-block-end: 20px;
}
.member6 {
  margin: 0;
  color: #00b5e2;
}
.kavindri-perera-member-container {
  width: 14.13rem;
  height: 2.75rem;
  position: relative;
  line-height: 0.75rem;
  text-transform: capitalize;
  display: inline-block;
}
.lead-pic-mahdy {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 1.31rem;
}
.member-anjula,
.member-mithila {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.member-anjula {
  box-sizing: border-box;
  text-align: center;
  font-size: 1.75rem;
}
.lorem-ipsum-dolor-sit-amet-co-parent,
.membership-dev-team {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 100%;
}
.lorem-ipsum-dolor-sit-amet-co-parent {
  align-self: stretch;
  align-items: center;
  gap: 3.88rem;
  text-align: justify;
  font-size: 0.94rem;
  color: #fff;
}
.membership-dev-team {
  width: 82.44rem;
  align-items: flex-start;
  box-sizing: border-box;
  gap: 3.25rem;
  min-height: 42.88rem;
  text-align: center;
  font-size: 2.63rem;
  color: #ffa300;
  font-family: Montserrat;
}
@media screen and (max-width: 1200px) {
  .member-mithila {
    flex-wrap: wrap;
  }
  .lorem-ipsum-dolor-sit-amet-co-parent {
    gap: 3.88rem;
  }
}
@media screen and (max-width: 1050px) {
  .membership-development-team {
    font-size: 2.13rem;
    line-height: 2rem;
  }
}
@media screen and (max-width: 750px) {
  .membership-development-team {
    font-size: 1.56rem;
    line-height: 2rem;
  }
  .kavindri-perera-member-container,
  .mahdy-abdullah-nawfar-container,
  .thiruni-bandara-member-container {
    font-size: 1.38rem;
    line-height: 0.63rem;
  }
  .member-mithila {
    justify-content: center;
    margin: 0 auto;
  }
  .membership-dev-team {
    gap: 2.56rem;
  }
}
@media screen and (max-width: 450px) {
  .membership-development-team {
    font-size: 1.56rem;
    line-height: 2rem;
  }
  .kavindri-perera-member-container,
  .mahdy-abdullah-nawfar-container,
  .thiruni-bandara-member-container {
    font-size: 1.38rem;
    line-height: 0.63rem;
  }
  .member-mithila {
    justify-content: center;
    margin: 0 auto;
  }
  .membership-dev-team {
    gap: 2.56rem;
  }
}
