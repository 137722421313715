.branch,
.ieee,
.sliit,
.student {
  margin: 0;
}
.ieee-student-branch-container {
  width: 97.38rem;
  height: 64.75rem;
  position: relative;
  letter-spacing: 0.2em;
  line-height: 160%;
  font-weight: 800;
  text-align: right;
  display: none;
  max-width: 100%;
  z-index: 0;
}
.blank-line {
  margin: 0;
}
.text {
  width: 97.38rem;
  height: 32.38rem;
  position: relative;
  letter-spacing: 0.2em;
  line-height: 160%;
  font-weight: 800;
  text-align: right;
  display: none;
  max-width: 100%;
  z-index: 1;
}
.gallery,
.logo {
  position: absolute;
  top: 0;
  display: inline-block;
}
.logo {
  left: 0;
  width: 6.45rem;
}
.gallery {
  left: 42.51rem;
  width: 3.89rem;
}
.about {
  left: 12.92rem;
  width: 3.39rem;
}
.about,
.contact-us,
.home {
  position: absolute;
  top: 0;
  display: inline-block;
}
.home {
  left: 0;
  font-weight: 800;
  width: 3.64rem;
}
.contact-us {
  left: 26.35rem;
  width: 5.87rem;
}
.gallery-parent,
.vector-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.vector-icon {
  height: 114.74%;
  width: 4.3%;
  top: 82.63%;
  right: -53.35%;
  bottom: -97.37%;
  left: 149.06%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  display: none;
}
.frame-parent {
  position: absolute;
  top: 0.56rem;
  left: 28.41rem;
  width: 46.4rem;
  height: 1.19rem;
  font-size: 0.89rem;
}
.logo-parent,
.screenshot-2024-01-14-17410s0 {
  position: relative;
  display: none;
  max-width: 100%;
}
.logo-parent {
  width: 74.81rem;
  height: 2.31rem;
  z-index: 2;
  font-size: 1.69rem;
  color: #ffa300;
}
.screenshot-2024-01-14-17410s0 {
  width: 87.31rem;
  height: 43.38rem;
  object-fit: cover;
  z-index: 3;
}
.sb-committee-child {
  align-self: stretch;
  height: 38rem;
  position: relative;
  background: linear-gradient(180.01deg, #00629b, rgba(0, 98, 155, 0));
  transform: rotate(-180deg);
  display: none;
  z-index: 4;
}
.layer-2-1,
.layer-2-2,
.layer-2-3,
.layer-2-4 {
  width: 51rem;
  height: 12rem;
  position: relative;
  object-fit: contain;
  display: none;
  max-width: 100%;
  z-index: 5;
}
.layer-2-2,
.layer-2-3,
.layer-2-4 {
  width: 42.06rem;
  height: 9.88rem;
  object-fit: cover;
  z-index: 6;
}
.layer-2-3,
.layer-2-4 {
  z-index: 7;
}
.layer-2-4 {
  width: 41.56rem;
  height: 9.75rem;
  object-fit: contain;
  z-index: 8;
}
.sb-committee-item {
  width: 0;
  height: 0;
  position: relative;
  display: none;
  z-index: 9;
}
.sb-committee-inner {
  width: 18.52rem;
  height: 18.52rem;
  position: relative;
  object-fit: contain;
  display: none;
  z-index: 11;
}
.membership,
.sub-committees {
  letter-spacing: 0.02em;
  line-height: 2.19rem;
}
.membership {
  margin: 0 !important;
  width: 97.44rem;
  height: 1.06rem;
  position: absolute;
  right: -53.12rem;
  bottom: 129.63rem;
  font-size: 9.38rem;
  text-transform: capitalize;
  font-weight: 900;
  font-family: Montserrat;
  color: rgba(255, 255, 255, 0.05);
  display: inline-block;
  z-index: 1;
}
.sub-committees {
  margin: 0;
  position: relative;
  font-size: inherit;
  text-transform: uppercase;
  font-weight: 700;
  font-family: inherit;
}
.frame-group,
.sub-committees-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.sub-committees-wrapper {
  flex-direction: row;
  padding: 0 1.25rem 4.25rem;
  box-sizing: border-box;
  max-width: 100%;
  font-size: 3rem;
  color: #fff;
  font-family: Montserrat;
}
.frame-group {
  flex-direction: column;
  gap: 9rem;
  flex-shrink: 0;
  justify-content: center;
  margin: 0 auto;
}
.frame-section,
.sb-committee {
  display: flex;
  justify-content: flex-start;
  box-sizing: border-box;
}
.frame-section {
  align-self: stretch;
  flex-direction: row;
  align-items: flex-start;
  max-width: 100%;
  flex-shrink: 0;
  margin: 0 auto;
  justify-content: center;
}
.sb-committee {
  width: 100%;
  position: relative;
  background-color: #00629b;
  overflow: hidden;
  flex-direction: column;
  align-items: center;
  padding: 9.94rem 0 6.88rem;
  gap: 5.5rem;
  letter-spacing: normal;
  text-align: center;
  font-size: 10.1rem;
  color: #f5f5f5;
  font-family: "Open Sans";
}
@media screen and (max-width: 1200px) {
  .frame-group {
    gap: 10.19rem;
  }
  .frame-section {
    padding-left: 3.69rem;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 1050px) {
  .ieee-student-branch-container,
  .text {
    font-size: 2.5rem;
    line-height: 6.44rem;
  }
  .logo {
    font-size: 1.38rem;
  }
  .membership {
    font-size: 2.31rem;
    line-height: 0.88rem;
  }
  .sub-committees {
    font-size: 1.81rem;
    line-height: 1.31rem;
  }
  .frame-group {
    gap: 10.19rem;
    width: 100%;
  }
  .sb-committee {
    gap: 5.5rem;
  }
  .frame-section {
    width: 100%;
    margin-right: 20rem;
    padding: 1rem;
  }
}
@media screen and (max-width: 750px) {
  .ieee-student-branch-container,
  .text {
    font-size: 2.5rem;
    line-height: 6.44rem;
  }
  .logo {
    font-size: 1.38rem;
  }
  .membership {
    font-size: 2.31rem;
    line-height: 0.88rem;
  }
  .sub-committees {
    font-size: 1.81rem;
    line-height: 1.31rem;
  }
  .frame-group {
    gap: 10.19rem;
    width: 100%;
  }
  .sb-committee {
    gap: 5.5rem;
  }
  .frame-section {
    width: 100%;
    margin-right: 20rem;
    padding: 1rem;
  }
}
@media screen and (max-width: 450px) {
  .ieee-student-branch-container,
  .text {
    font-size: 2.5rem;
    line-height: 6.44rem;
  }
  .logo {
    font-size: 1.38rem;
  }
  .membership {
    font-size: 2.31rem;
    line-height: 0.88rem;
  }
  .sub-committees {
    font-size: 1.81rem;
    line-height: 1.31rem;
  }
  .frame-group {
    gap: 8rem;
    width: 100%;
  }
  .sb-committee {
    gap: 3.5rem;
  }
  .frame-section {
    width: 100%;
    margin-right: 20rem;
    padding: 1rem;
  }
}
