/*Montserrat*/
@import url("https://fonts.googleapis.com/css2?family=Kalam&family=Lily+Script+One&family=Monoton&family=Montserrat:wght@500;600;700;800&family=Roboto&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Montserrat;
}

.footer {
  color: #ffffff;
  background-color: #00629B;
  /* border: 2px solid black; */
  box-shadow: 0px 0px 5px 5px rgba(180, 180, 180, 0.6);
  
}
.footer-logo {
  margin: auto;
  padding: 30px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-logo img{
  width: 300px;
}

.footer-columns {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: center;
  max-width: 90%;
  margin: 0 auto;
}

.footer-ul{
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  
  gap: 10px;
}

.footer-column:nth-child(4) .footer-ul li{
  display: flex;
}


.footer-column .footer-ul {
  list-style: none;
  color: white;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  
}

.footer-column .footer-li {
  color: white;
  font-size: 18px;
  cursor: pointer;
  font-weight: 500;
}

.footer-hr {
  border-top: 2px solid yellow;
  margin: 15px 0;
}

.footer-social-icons {
  text-align: center;
  display: flex;
    justify-content: center;
}

.footer-text {
  color: white;
  font-size: 16px;
  font-style: normal;
  /* font-weight: 700; */
  line-height: 18px; /* 128.571% */
  text-align: center;
  padding: 15px 50px;
  margin: 0;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .footer-columns {
    grid-template-columns: 1fr;
  }
  .footer-column .footer-li {
    text-align: justify;
    padding: 8px 0 0 0;
  }
  .footer-column {
    flex: 1 0 100%;
  }
  .footer {
    padding: 20px;
  }

  .footer-h4{
    text-align: center;
  }

  .footer-text{
    padding: 15px 5px;
  }

  .footer-ul{
    align-items: center;  }

  .footer-column:nth-child(1),
  .footer-column:nth-child(2),
  .footer-column:nth-child(3){
    display: none;
  }
}
