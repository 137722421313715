@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap");

.join_main {
  height: 1500px;
  width: 100vw;
  display: flex;
  flex-direction: column;
}

.join_hero {
  background-image: url("./images/Rectangle.png");
  background-size: cover;
  flex: 4;
  width: 100%;
}

.join_container {
  display: flex;
  flex-direction: column;
  flex: 7;
  margin: 10%;
  margin-top: 0;
}

.join_container h1 {
  margin-top: 5%;
  flex: 0.3;
  text-align: center;
  color: #00629b;

  fontfamily: "Inter, sans-serif";
  font-size: 30px;
  font-weight: 700;
}
.join_c1 {
  display: flex;

  flex-direction: column;
  flex: 3;
  background-color: rgb(255, 255, 255);
}
.join_c1 p {
  flex: 0.4;

  fontfamily: "Inter, sans-serif";
  font-size: 21px;
  font-weight: 700;
}

.join_lbox {
  flex: 3;
  display: flex;
  flex-direction: row;
  justify-items: center;
  justify-content: space-evenly;
  margin: 0;
}
.join_sbox {
  width: 40%;
  border-color: #00629b;
  border-width: 3px;
  border-radius: 5px;
  border-style: solid;
  margin: 10px;
  align-items: center;
  justify-content: center;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}
.join_sbox p {
  justify-content: center;
  text-align: justify;
  align-content: center;
  fontfamily: "Inter, sans-serif";
  font-size: 21px;
  font-weight: 600;
  text-align: center;
  margin: 10%;
}

.box {
  background-color: #00629b;
  padding: 10px;
  width: 63%;
  border-radius: 5px;
  margin-left: 10px;
  margin-top: 2%;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.441);
}

.join_last {
  flex: 1;

  background-color: #00629b;
  margin: 10px;
  border: white;
  border-style: dashed;
  border-width: 2px;
  border-radius: 5px;
}
.join_last p {
  margin: 3%;
  color: white;
  fontfamily: "Inter, sans-serif";
  font-size: 21px;
}
.join_last button {
  background-color: #f1c200;
  width: 100px;
  height: 40px;
  align-items: center;
  border: none;
  margin: 30px;
  fontfamily: "Inter, sans-serif";
  font-size: 22px;
  border-radius: 5px;
  transition: 0.3s;
}

.join_last button:hover {
  cursor: pointer;
  background-color: rgb(141, 182, 8);
}
